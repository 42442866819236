import twitter from "./twitter_icon.svg"
import line from "./line_icon.svg"
import facebook from "./facebook_icon.svg"
import youtube from "./youtube_logo.svg"
import share from "./share.svg"

export const twitterIcon = twitter
export const lineIcon = line
export const facebookIcon = facebook
export const youtubeIcon = youtube
export const shareText = share
