import React from 'react';
import { css } from '@emotion/react';
import * as svgPath from '../../images/svgPath';

const ShareButton: React.FC = () => {
  return (
    <>
      <div className="share-list-wrap">
        <h3 css={shareTitle}>
          <img src={svgPath.shareText} alt="SHARE!" width="183" height="29" />
        </h3>
      </div>
      <ul css={shareList}>
        <li className="share">
          <a
            href="https://twitter.com/intent/tweet?text=最先端のバーチャルアイドルONLINEライブフェス「Life Like a Live!7」2024年03月22日～24日開催！&url=https://lifelikealive.zan-live.com&hashtags=えるすりー&via=LifeLikeaLive"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <img src={svgPath.twitterIcon} alt="X アイコン" width="55" height="55" />
          </a>
        </li>
        <li className="share">
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=https://lifelikealive.zan-live.com"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <img src={svgPath.facebookIcon} alt="facebook アイコン" width="55" height="55" />
          </a>
        </li>
        <li className="share">
          <a
            href="https://social-plugins.line.me/lineit/share?url=https://lifelikealive.zan-live.com"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <img src={svgPath.lineIcon} alt="Line アイコン" width="55" height="55" />
          </a>
        </li>
      </ul>
    </>
  );
};

const shareTitle = css`
  margin: 45px 0 15px;
  text-align: center;
  img {
    max-width: 145px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 767px) {
    margin: 35px 0 15px;
    img {
      max-width: 121px;
    }
  }
`;

const shareList = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  li {
    margin: 0 10px;
    img {
      max-width: 45px;
    }
  }

  @media (min-width: 768px) {
    li {
      a {
        img {
          transition: all 0.3s ease;
        }
      }
    }
    li:hover {
      a {
        img {
          transform: scale(1.08);
        }
      }
    }
  }
  @media (max-width: 767px) {
    li {
      margin: 0 6px;
      img {
        max-width: 30px;
      }
    }
  }
`;

export default ShareButton;
